// src/components/Ingredents/Ingredents.js
import React from "react";
import "../Ingredents/Ingredents.css";
import OurIngredients from "./OurIncredients";
import Incre from "./Incre";
import Move from "../Move/Move";

const Ingredents = () => {
  return (
    <>
      <Move/>
      <section className="incre-p">
        <div className="left-incre">
          <h5 className="incre-h5">Pure Ingredients, Exceptional Quality</h5>
          <h2 className="incre-h">Nature's Best In Every Bottle</h2>
          <p className="incre-p">Rich in Nutrients & Highly Effective</p>
        </div>
        <div className="right-incre">
          <img src="/ingredient1.jpg" alt="Description" />
        </div>
      </section>
      <p className="text-bottle">
        We put as many ingredients in one bottle as much it takes to heal the
        concern holistically and help improve overall health of the skin. We
        believe that one bottle should solve your primary & secondary concerns
        and help keep your skin healthy and nourished. Thus can keep you regime
        minimalist and quick. Every day is different for your skin like the rest
        of our physical, mental and emotional self. Our self-care regime should
        reflect this natural flux.
      </p>

      <section className="i-count">
        <div className="count-item">
          <h2>80+</h2>
          <h3>HIGH PERFORMANCE INGREDIENTS</h3>
          <p>
            We focus on multi-targeted solutions for your skin. Hence our products are filled with a unique blend of active nutrients. Experience the care, peptides, vitamins, botanical extracts, energizing minerals, amino-acids and many more powered with advanced technologies.
          </p>
        </div>
        <div className="count-item">
          <h2>40+</h2>
          <h3>COUNTRIES</h3>
          <p>
            We scout the world to source high quality ingredients, precious plant extracts, magical oils and unique molecules. Efficacy, safety and sustainability are our criteria of selection, ensuring that each formula improves the way your skin looks and feels good.
          </p>
        </div>
        <div className="count-item">
          <h2>200+</h2>
          <h3>RAW INGREDIENTS</h3>
          <p>
            Unique base ingredients with long lasting hydrating effect, nourishing seed oils, energizing essential oils, floral waters, et.al. We’re always innovating and upgrading with revolutionary ingredients, understanding the skin science to make suitable products and provide a feeling of wellbeing.
          </p>
        </div>
      </section>

      <section className="incre-f">
        <h2 className="incre-f-heading">Our Featured Nutrients</h2>
        <div className="incre-f-feature">
          <h3 className="incre-f-feature-title">Manicouagan Clay</h3>
          <div className="fullcc">
          <img src="/i1.webp" alt="Feature" className="incre-f-feature-img" />
          <div className="divt">
          <p className="incre-f-feature-description">
            Located in the northern part of Canada, is one of the richest in minerals and trace elements in the world. Of the 102 known elements of the earth, we find more than 42 contents in this unique clay. Manicouagan Lake was born first by the impact of a meteorite more than 200 million years ago, which gives it its rounded shape visible from space.
          </p>
          </div>
          </div>
        </div>
        <div className="incre-f-feature">
          <h3 className="incre-f-feature-title">Biden Pilosa</h3>
          <div className="fullcc">
          <img src="/i2.webp" alt="Feature" className="incre-f-feature-img" />
          <div className="divt">
          <p className="incre-f-feature-description">
            BioRetinol is a super critical fluid apolar extract of Bidens pilosa. Rich phytochemical composition, standardized in phytol. It is with retinoids receptors activity, mimicking the same positive results that retinoids to fight against skin aging. Skin visibly younger, luminous and firm, reducing reduces wrinkles, improves skin elasticity and hydration.
          </p>
          </div>
          </div>
        </div>
        <div className="incre-f-feature">
          <h3 className="incre-f-feature-title">Nordic Peat</h3>
          <div className="fullcc">
          <img src="/i3.jpg" alt="Feature" className="incre-f-feature-img" />
          <div className="divt">
          <p className="incre-f-feature-description">
            Peat originates in swamp, where it naturally develops over hundreds of years. Finnish nature is unique due to its summers with long days and winters with freezing temperatures. Therefore the plants have a short but intense growth season and are richer in nutrients than plants grown elsewhere. Herbs, flowers and plants are naturally macerated by microbial action enriching the peat with high concentration of bio-active substances.
          </p>
          </div>
          </div>
        </div>
      </section>
     <Incre/>
   
    </>
  );
};

export default Ingredents;

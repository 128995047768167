import React from 'react';
import Slider from 'react-slick';
import '../Mimg/Mimg.css'; 

const Mimg = () => {
  const images = [
    'https://img.freepik.com/premium-photo/woman39s-face-partially-hidden-by-lush-green-leaves_239711-10922.jpg?w=996',
    'https://img.freepik.com/premium-photo/woman-pouring-lotion-into-hand-cosmetic-product-branding-mockup_1270664-24366.jpg?w=826',
    'https://img.freepik.com/premium-photo/woman-is-sitting-tree-with-her-eyes-closed_248459-22478.jpg?w=996',
    'https://img.freepik.com/free-photo/young-woman-relaxing-spa_144627-34922.jpg?w=996',
    'https://img.freepik.com/free-photo/beautiful-young-woman-relaxing-spa-salon_144627-37210.jpg?w=996',
    'gs4.jpg'
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Hides arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="mimg-container">
    <h1 className='mimg-head'>New Cosmetologist</h1>
      <Slider {...settings}>
        {images.map((img, index) => (
          <div key={index} className={`image-slide ${index % 2 === 0 ? 'large-slide' : ''}`}>
            <div className="image-overlay">
              <img src={img} alt={`Slide ${index}`} className="slider-image" />
              <div className="overlay">
                <button className="shop-now-btn">Shop Now</button>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Mimg;

import React, { useState, useRef, useEffect } from 'react';
import '../Seller/Seller.css';
import { Link } from 'react-router-dom';

const Seller = () => {
  const products = [
    {
      id: 1,
      image: 'p.jpg',
      title: 'Product 1',
      description: 'This is the description for product 1.',
      price: 1500,
      size: '50gm',
      emi: 'No EMI available',
      rating: 4.5,
      reviews: 20
    },
    {
      id: 2,
      image: 'p.jpg',
      title: 'Product 2',
      description: 'This is the description for product 2.',
      price: 2500,
      size: '100gm',
      emi: 'EMI available from ₹200/month',
      rating: 4.7,
      reviews: 35
    },
    {
      id: 3,
      image: 'p.jpg',
      title: 'Product 3',
      description: 'This is the description for product 3.',
      price: 1200,
      size: '150gm',
      emi: 'EMI available from ₹150/month',
      rating: 4.3,
      reviews: 15
    },
    {
      id: 4,
      image: 'p.jpg',
      title: 'Product 4',
      description: 'This is the description for product 4.',
      price: 3000,
      size: '40gm',
      emi: 'No EMI available',
      rating: 4.9,
      reviews: 40
    },
    {
      id: 5,
      image: 'p.jpg',
      title: 'Product 5',
      description: 'This is the description for product 5.',
      price: 5000,
      size: '50gm',
      emi: 'EMI available from ₹500/month',
      rating: 4.8,
      reviews: 50
    },
    {
      id: 6,
      image: 'p.jpg',
      title: 'Product 6',
      description: 'This is the description for product 6.',
      price: 1800,
      size: '100gm',
      emi: 'No EMI available',
      rating: 4.2,
      reviews: 22
    }
  ];

  const sliderRef = useRef(null);
  const [scrollDirection, setScrollDirection] = useState('right'); // Keep track of the scroll direction

  // Auto scroll functionality
  useEffect(() => {
    const slider = sliderRef.current;
    let scrollInterval;

    const startAutoScroll = () => {
      scrollInterval = setInterval(() => {
        if (scrollDirection === 'right') {
          slider.scrollLeft += 2; // Scroll to the right
          if (slider.scrollLeft + slider.offsetWidth >= slider.scrollWidth) {
            setScrollDirection('left'); // Reverse scroll direction when reaching the end
          }
        } else {
          slider.scrollLeft -= 2; // Scroll to the left
          if (slider.scrollLeft <= 0) {
            setScrollDirection('right'); // Reverse scroll direction when reaching the start
          }
        }
      }, 30); // Adjust speed (lower value = faster scroll)
    };

    startAutoScroll();

    return () => clearInterval(scrollInterval); // Cleanup on component unmount
  }, [scrollDirection]);

  return (
    <div className="seller-section">
      <img src='petals.png' className='img-petals2' alt='petal'/>
      <img src='petals2.png' className='img-petals' alt='petal'/>
      <h2 className="seller-heading">Best Seller</h2>
      <div className="slider-container" ref={sliderRef}>
        {products.map((product) => (
          <div key={product.id} className="product-card">
            <img src={product.image} alt={product.title} className="product-image" />
            <h3 className="title-p">{product.title}</h3>
            <p className="description">{product.description}</p>
            <div className="fl-p">
              <div className="price">{`₹ ${product.price}`}</div>
              <p className='p-size'>{product.size}</p>
            </div>
           
            <p className='star'>{`⭐ ${product.rating} stars | ${product.reviews} reviews`}</p>
            <Link to="/contact">
              <button className="view-product">SHOP NOW</button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Seller;

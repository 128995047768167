// src/components/Blog.js
import React, { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Blog/Blog.css";
import { useLocation } from 'react-router-dom';

// Dummy Blog Data
const blogData = [
  {
    id: 1,
    category: "Lab Stories",
    title: "Introduction to Plant-Based Skincare",
    description:
      "In recent years, the beauty industry has seen a significant shift towards plant-based skincare products. This trend isn't just a passing fad... ",
    image: "b1.jpg",
    content: `# In recent years, the beauty industry has seen a significant shift towards plant-based skincare products.
This trend isn't just a passing fad; it's a return to nature's wisdom that has been used for centuries.
Plant-based skincare offers a holistic approach to skin health, harnessing the power of natural
ingredients to nourish, protect, and rejuvenate our skin.
Plant-based skincare products are formulated using ingredients derived from plants, including herbs,
fruits, flowers, and seeds. These natural ingredients are often packed with vitamins, minerals, and other
beneficial compounds that can work wonders for our skin. Unlike synthetic ingredients, plant-based
components are more likely to be compatible with our skin, reducing the risk of irritation and adverse
reactions. 

## Incorporating Plant-Based Ingredients into Your Routine

Now that you know these 10 plant-based skincare secrets, it's time to incorporate them into your
routine. Start by identifying your skin's needs and choosing the ingredients that best address
those concerns. Remember, everyone's skin is unique, so what works for one person may not
work for another. Don't be afraid to experiment and find the perfect combination for your skin

## The Benefits of Switching to Plant-Based Skincare

Switching to plant-based skincare offers numerous benefits. Not only are you nourishing your
skin with natural ingredients, but you're also reducing your exposure to potentially harmful
chemicals. Plant-based skincare is often gentler on the skin and better for the environment.
Plus, many people find that their skin looks and feels healthier after making the switch.

## Conclusion


Embracing plant-based skincare is more than just a trend – it's a way to harness the power of
nature for healthier, more radiant skin. By incorporating these 10 plant-based skincare secrets
into your routine, you'll be well on your way to achieving the glowing, beautiful skin you've
always dreamed of. Remember, the key to great skin is consistency and patience. Give your
skin time to adjust to new ingredients and enjoy the journey to naturally beautiful skin!



- **Why 3E Skin Route is better?**  
  Have you ever noticed that little squiggly symbol on 3E Skin Route's website? It may be small, but it holds power - signifying that you’re helping to save ...
    `,
  },
  {
    id: 2,
    category: "Skin Care",
    title: "The Benefits of Clean Ingredients ",
    description:
      "One of the key advantages of plant-based skincare is its focus on clean ingredients.Clean ingredients are those that are naturally derived...",
    image: "b2.avif",
    content: `# Clean ingredients in plant-based skincare products offer several benefits: 

One of the key advantages of plant-based skincare is its focus on clean ingredients. Clean ingredients are
those that are naturally derived, minimally processed, and free from harmful chemicals. These
ingredients are not only better for our skin but also for the environment. 

## Benefits of Clean Ingredients

- **Reduced risk of irritation**: Natural ingredients are less likely to cause skin irritation or allergic reactions
compared to synthetic chemicals. 
- **Nutrient-rich**: Many plant-based ingredients are packed with vitamins, minerals, and antioxidants that
nourish and protect the skin. 
- **Transparency**: Brands that focus on clean ingredients often provide clear information about their
product formulations, allowing consumers to make informed choices. 
- **Anti-Aging**: Promotes collagen production for youthful skin.

## The Power of Clean Ingredients

- **Free from harmful chemicals and toxins**
- **Minimally processed**
- **Transparently sourced**
- **Safe for long-term use**

---

Stay tuned for more insights into effective skincare ingredients!
    `,
  },
  {
    id: 3,
    category: "We Care",
    title: "Mild Ingredients for Sensitive Skin ",
    description:
      "For those with sensitive skin, finding suitable skincare products can be a challenge. Skincare with mild ingredients comes to the rescue...",
    image: "b3.jpg", // Dummy Image
    content: `# For those with sensitive skin, finding skincare products can be a challenge. 
Plant-based skincare with mild ingredients comes to the rescue. Mild ingredients are gentle on the skin,
less likely to cause irritation, and suitable for daily use

## Some examples of mild plant-based ingredients include: 

- **Aloe vera**: Known for its soothing and hydrating properties
- **Chamomile**: Calms and reduces inflammation 
- **Oatmeal**: Moisturizes and protects the skin barrier 
- **Calendula**: Heals and soothes irritated skin 

## 3E Skin Route’s Commitment

These mild ingredients can be found in various skincare products, from cleansers to moisturizers, making
it easier for those with sensitive skin to build a complete skincare routine. 

---

Explore our range of 3E Skin Route skincare products and join us in making a positive impact!
    `,
  },
  {
    id: 4,
    category: "Lab Stories",
    title: "Customized Skincare: Tailoring to Your Needs ",
    description:
      "One of the most exciting aspects of plant-based skincare is the ability to customize products to suit individual skin needs. With a wide variety...",
    image: "b4.jpg", // Dummy Image
    content: `# One of the most exciting aspects of plant-based skincare is the ability to customize products to suit
individual skin needs. With a wide variety of plant-based ingredients available, it's possible to create
formulations that address specific skin concerns. 

## Customized skincare can be achieved through:

- **Personalized consultations:**:3E SKINROUTE offers skin assessments to recommend products tailored to
your skin type and concerns. 
- **Mix-and-match approach**: Combining different plant-based products to create a routine that works for
your skin. 
- **Targeted treatments**: : Using specific plant extracts to address particular skin issues, such as acne,
hyperpigmentation, or aging. 

## 3E Skin Route’s Commitment

At 3E Skin Route, we believe in creating products that not only benefit your skin but also the planet. Our vegan skincare line is crafted with care, ensuring that each product is both effective and ethical.

---

Explore our range of 3E Skin Route skincare products and join us in making a positive impact!
    `,
  },
  {
    id: 5,
    category: "Skin Care",
    title: "Antioxidants: Nature's Skin Defenders ",
    description:
      "Plant-based skincare products are often filled with antioxidants, which are crucial for maintaining healthy skin...",
    image: "b5.jpg", // Dummy Image
    content: `# Plant-based skincare products are often filled with antioxidants, which are crucial for maintaining healthy
skin. Antioxidants help protect our skin from free radical damage caused by environmental stressors like
UV radiation and pollution. 


## Some powerful plant-based antioxidants include: 

- **Vitamin C**: Found in citrus fruits, rosehip, and sea buckthorn
- **Vitamin E**:  Present in nuts, seeds, and avocado 
- **Green tea**: Rich in polyphenols 

## 3E Skin Route’s Commitment

At 3E Skin Route, we believe in creating products that not only benefit your skin but also the planet. Our vegan skincare line is crafted with care, ensuring that each product is both effective and ethical.

---

Explore our range of 3E Skin Route skincare products and join us in making a positive impact!
    `,
  },
  {
    id: 6,
    category: "We Care",
    title: "Antibacterial and Antifungal Properties",
    description:
      "Many plant-based ingredients possess natural antibacterial and antifungal properties, making them effective in treating various skin conditions...",
    image: "b6.jpg", // Dummy Image
    content: `# These properties can help in

Many plant-based ingredients possess natural antibacterial and antifungal properties, making them
effective in treating various skin conditions

## Properties can help

- **Acne prevention**: By controlling bacteria growth on the skin 
- **Treating fungal infections**: Such as athlete's foot or ringworm
- **Maintaining skin balance**: By promoting a healthy skin microbiome 

## Some plant-based ingredients with antibacterial and antifungal properties include:
- **Tea tree oil**: Known for its potent antimicrobial effects
- **Neem**: Used in traditional medicine for its antiseptic properties
- **Oregano oil**: Contains compounds that fight bacteria and fungi
- **Thyme**: Has natural antimicrobial properties 

## 3E Skin Route’s Commitment

At 3E Skin Route, we believe in creating products that not only benefit your skin but also the planet. Our vegan skincare line is crafted with care, ensuring that each product is both effective and ethical.

---

Explore our range of 3E Skin Route skincare products and join us in making a positive impact!
    `,
  },

  {
    id: 7,
    category: "Lab Stories",
    title: "Plant Power: From Ancient Times to Modern Day ",
    description:
      "The use of plants for skincare is not a new concept. In fact, it dates back to ancient times when various civilizations used plant-based remedies for skin health and beauty...",
    image: "b7.avif", // Dummy Image
    content: `# Today, we're seeing a revival of these ancient practices

combined with modern scientific research. This
blend of traditional wisdom and contemporary knowledge has led to the development of highly effective
plant-based skincare products. 


## Some examples of ancient plant-based skincare practices that are still relevant today include
- **Ayurvedic skincare**: Using herbs and plants like turmeric, neem, and sandalwood
- **Traditional Chinese Medicine**: Incorporating ingredients like ginseng and green tea
- **African botanicals**: Utilizing plants like marula oil and baobab 

## 3E Skin Route’s Commitment

At 3E Skin Route, we believe in creating products that not only benefit your skin but also the planet. Our vegan skincare line is crafted with care, ensuring that each product is both effective and ethical.

---

Explore our range of 3E Skin Route skincare products and join us in making a positive impact!
    `,
  },
  {
    id: 8,
    category: "Skin Care",
    title: "Conclusion: Embracing Plant-Based Skincare",
    description:
      "Plant-based skincare offers a natural, effective, and sustainable approach to skin health With its focus on clean and mild ingredients...",
    image: "b8.jpg", // Dummy Image
    content: `# With its focus

on clean and mild ingredients, customized solutions, and powerful natural compounds, it's no wonder
that more and more people are turning to plant-based products for their skincare needs....


## Conclusion

- **By incorporating plant-based skincare into your routine**: you're not just taking care of your skin; you're
also making a choice that's better for the environment. Whether you're dealing with sensitive skin,
looking for anti-aging solutions, or simply want to maintain healthy skin, there's a plant-based product
out there for you.
- **Remember, everyone's skin is unique**: so it may take some time to find the right combination of
plant-based products that work for you. Don't be afraid to experiment and consult with skincare
professionals to create a routine that suits your specific needs.
- **Are you ready to harness the power of plants for your skin**: Start exploring plant-based skincare options
today and experience the difference nature can make for your skin health and overall well-being. 

## 3E Skin Route’s Commitment

At 3E Skin Route, we believe in creating products that not only benefit your skin but also the planet. Our vegan skincare line is crafted with care, ensuring that each product is both effective and ethical.

---

Explore our range of 3E Skin Route skincare products and join us in making a positive impact!
    `,
  },
  {
    id: 9,
    category: "We Care",
    title: "Slows Down Aging",
    description:
      "Using beauty products that are rich in antioxidants and nourishing ingredients helps to slow down the visible signs of aging....",
    image: "b9.jpg", // Dummy Image
    content: `# Aging is a natural part of life, but who says we can’t slow it down?

How Beauty Products Help Slow Down Aging?...

## Benefits of Anti-Aging Beauty Products

- **Slows Down the Visible Signs of Aging**: Using products designed to combat aging helps reduce the appearance of wrinkles, fine lines, and age spots, giving your skin a smoother and more youthful look over time.
- **Enhances Skin Elasticity**: Anti-aging products boost collagen production and improve skin firmness, which can prevent sagging and give the skin a more lifted appearance.
- **Hydrates and Nourishes the Skin**: Proper hydration is essential for youthful skin. Anti-aging moisturizers provide deep hydration, ensuring your skin stays soft and supple, with fewer dry patches and less irritation.

## 3E Skin Route’s Commitment

At 3E Skin Route, we believe in creating products that not only benefit your skin but also the planet. Our vegan skincare line is crafted with care, ensuring that each product is both effective and ethical.

---

Explore our range of 3E Skin Route skincare products and join us in making a positive impact!
    `,
  },
];

const Blog = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const navigate = useNavigate();

  const handleReadMore = (post) => {
    navigate(`/blog/${post.id}`, { state: { post } });
  };
  
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const filteredPosts =
    selectedCategory === "All"
      ? blogData
      : blogData.filter((post) => post.category === selectedCategory);

  return (
    <div className="blog-section">
      {/* Poster Image */}
      <div className="blog-poster">
        <img
          src="https://img.freepik.com/premium-photo/closeup-portrait-beautiful-young-woman-with-confetti-pink-background-holiday-concept-birthday-sale-discount-clothes-cosmetics-makeup-beauty-fashion-spring_248459-28796.jpg?w=996" // Dummy Poster Image
          alt="Blog Poster"
          data-aos="zoom-in"
          className="poster-image"
        />
      </div>

      {/* Toggle Buttons */}
      <div className="blog-toggle">
        <button
          className={selectedCategory === "All" ? "active" : ""}
          onClick={() => setSelectedCategory("All")}
        >
          All
        </button>
        <button
          className={selectedCategory === "Lab Stories" ? "active" : ""}
          onClick={() => setSelectedCategory("Lab Stories")}
        >
          Lab Stories
        </button>
        <button
          className={selectedCategory === "Skin Care" ? "active" : ""}
          onClick={() => setSelectedCategory("Skin Care")}
        >
          Skin Care
        </button>
        <button
          className={selectedCategory === "We Care" ? "active" : ""}
          onClick={() => setSelectedCategory("We Care")}
        >
          We Care
        </button>
      </div>

      {/* Blog Cards */}
      <div className="blog-grid">
        {filteredPosts.map((post) => (
          <div className="blog-card" key={post.id}>
            <div className="blog-card-image">
              <img data-aos="flip-up" src={post.image} alt={post.title} />
            </div>
            <div className="blog-card-content">
              <h4>{post.title}</h4>
              <p>{post.description}</p>
              <span className="read-more" onClick={() => handleReadMore(post)}>
                Read More
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;


import React, { useRef } from 'react';
import '../Ingredents/Incre.css'; // Correct path to CSS file

// Define three different ingredient arrays and headings
const ingredientSections = [
  {
    heading: 'Our Ingredients',
    ingredients: [
      { id: 1, image: 'p.jpg', title: 'Ingredient 1', description: 'Description for ingredient 1.' },
      { id: 2, image: 'p.jpg', title: 'Ingredient 2', description: 'Description for ingredient 2.' },
      { id: 3, image: 'p.jpg', title: 'Ingredient 3', description: 'Description for ingredient 3.' },
      { id: 4, image: 'p.jpg', title: 'Ingredient 1', description: 'Description for ingredient 1.' },
      { id: 5, image: 'p.jpg', title: 'Ingredient 2', description: 'Description for ingredient 2.' },
      { id: 6, image: 'p.jpg', title: 'Ingredient 3', description: 'Description for ingredient 3.' },
      // Add more ingredients as needed
    ],
  },
  {
    heading: 'Fresh Picks',
    ingredients: [
      { id: 7, image: 'p.jpg', title: 'Ingredient 4', description: 'Description for ingredient 4.' },
      { id: 8, image: 'p.jpg', title: 'Ingredient 5', description: 'Description for ingredient 5.' },
      { id: 9, image: 'p.jpg', title: 'Ingredient 6', description: 'Description for ingredient 6.' },
      // Add more ingredients as needed
      { id: 10, image: 'p.jpg', title: 'Ingredient 4', description: 'Description for ingredient 4.' },
      { id: 11, image: 'p.jpg', title: 'Ingredient 5', description: 'Description for ingredient 5.' },
      { id: 12, image: 'p.jpg', title: 'Ingredient 6', description: 'Description for ingredient 6.' },
      // Add more ingredients as needed
    ],
  },
  {
    heading: 'Top Choices',
    ingredients: [
      { id: 13, image: 'p.jpg', title: 'Ingredient 7', description: 'Description for ingredient 7.' },
      { id: 14, image: 'p.jpg', title: 'Ingredient 8', description: 'Description for ingredient 8.' },
      { id: 15, image: 'p.jpg', title: 'Ingredient 9', description: 'Description for ingredient 9.' },
      { id: 16, image: 'p.jpg', title: 'Ingredient 7', description: 'Description for ingredient 7.' },
      { id: 17, image: 'p.jpg', title: 'Ingredient 8', description: 'Description for ingredient 8.' },
      { id: 18, image: 'p.jpg', title: 'Ingredient 9', description: 'Description for ingredient 9.' },
      // Add more ingredients as needed
    ],
  },
];

const Incre = () => {
  const scrollContainerRef = useRef(null);

  const handleWheel = (event) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += event.deltaY;
    }
  };

  return (
    <div className="incre-container">
      {ingredientSections.map((section, index) => (
        <section key={index} className="incre">
          <h2 className="incre-heading">{section.heading}</h2>
          <div
            className="incre-grid"
            ref={scrollContainerRef}
            onWheel={handleWheel}
          >
            {section.ingredients.map((item) => (
              <div key={item.id} className="incre-item">
                <div className="incre-image" style={{ backgroundImage: `url(${item.image})` }}></div>
                <div className="incre-text">
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </section>
      ))}
    </div>
  );
};

export default Incre;

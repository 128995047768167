import React, { useState } from 'react';
import '../Products/Category.css';

const Category = ({ onCategoryClick }) => {
  const [selectedCategory, setSelectedCategory] = useState('facialCare');

  const categories = {
    facialCare: [
      { id: 1, name: 'Cleanser', image: 'p.jpg' },
      { id: 2, name: 'Serum', image: 'p.jpg' },
      { id: 3, name: 'Moisturizer', image: 'p.jpg' },
      { id: 4, name: 'Exfoliation', image: 'p.jpg' },
      { id: 5, name: 'Face Mask', image: 'p.jpg' },
      { id: 6, name: 'Face Butter', image: 'p.jpg' },
      { id: 7, name: 'Eye Cream', image: 'p.jpg' },
      { id: 8, name: 'Restoration Cream', image: 'p.jpg' },
      { id: 9, name: 'Face Oil', image: 'p.jpg' },
    ],
    hairCare: [
      { id: 10, name: 'Protein Shampoo', image: 'p.jpg' },
      { id: 11, name: 'Conditioner', image: 'p.jpg' },
      { id: 12, name: 'Hair Serum', image: 'p.jpg' },
    ],
    bodyCare: [
      { id: 13, name: 'Cold Press Soap', image: 'p.jpg' },
      { id: 14, name: 'Moisturizer Body', image: 'p.jpg' },
      { id: 15, name: 'Whipped Soap', image: 'p.jpg' },
    ],
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const renderCategorySection = (category) => {
    const items = categories[category] || [];

    if (items.length === 0) return null;

    return (
      <div className="category-section-container">
        <img src='sp.png' className='img-petals22' alt='petal'/>
        <img src='sp2.png' className='img-petals1' alt='petal'/>
        <div className="category-cards">
          {items.map((item) => (
            <div
              key={item.id}
              className="category-card"
              onClick={() => onCategoryClick(item.name)}
            >
              <img
                src={item.image}
                alt={item.name}
                className="category-image"
              />
             <div className="category-name">
              <p className='c-name'>{item.name}</p>
            </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <section className="category-section">
      <h2 className='h-c'>Shop by Category</h2>

      <div className="toggle-buttons">
        <button
          className={`toggle-btn ${selectedCategory === 'facialCare' ? 'active' : ''}`}
          onClick={() => handleCategoryClick('facialCare')}
        >
          Facial Care
        </button>
        <button
          className={`toggle-btn ${selectedCategory === 'hairCare' ? 'active' : ''}`}
          onClick={() => handleCategoryClick('hairCare')}
        >
          Hair Care
        </button>
        <button
          className={`toggle-btn ${selectedCategory === 'bodyCare' ? 'active' : ''}`}
          onClick={() => handleCategoryClick('bodyCare')}
        >
          Body Care
        </button>
      </div>

      {renderCategorySection(selectedCategory)}
    </section>
  );
};

export default Category;

import React, { useState } from 'react';
import '../Contact/Contact.css'; // Ensure the correct path for your CSS file
import { FaPhoneAlt, FaFacebookF, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { IoTimerSharp } from "react-icons/io5";
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

const Contact = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    mobile: '',
    age: '',
    concern: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Validation logic
  const validate = (name, value) => {
    let errors = { ...formErrors };
    switch (name) {
      case 'name':
        errors.name = /^[A-Za-z\s]+$/.test(value) ? '' : 'Name should contain only alphabets.';
        break;
      case 'email':
        errors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Invalid email address.';
        break;
      case 'mobile':
        errors.mobile = /^\d{10}$/.test(value) ? '' : 'Mobile number should be 10 digits.';
        break;
      case 'age':
        errors.age = value > 0 && value <= 50 ? '' : 'Age between 1 and 50.';
        break;
      case 'concern':
        errors.concern = value.length >= 15 ? '' : 'Concern should be at least 15 characters long.';
        break;
      default:
        break;
    }
    setFormErrors(errors);
  };

  // Handling form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    validate(name, value);
  };

  // Getting dates for the current month
  const getDatesForCurrentMonth = () => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const dates = [];
  
    for (let day = startOfMonth; day <= endOfMonth; day.setDate(day.getDate() + 1)) {
      const date = new Date(day);
      dates.push(date);
    }
    return dates;
  };

  const dates = getDatesForCurrentMonth();
  const times = ['10:00 AM', '11:00 AM', '12:00 PM', '01:00 PM', '02:00 PM'];

  // Date and time selection handlers
  const handleDateChange = (date) => {
    const today = new Date();
    if (date >= today.setHours(0, 0, 0, 0)) {
      setSelectedDate(date);
      setSelectedTime(null); // Reset time when date changes
    }
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const handleNextClick = () => {
    setShowForm(true); // Show form after clicking Next
  };

  // Form submission handler
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formattedDate = selectedDate ? selectedDate.toISOString().split('T')[0] : '';

    const formData = {
      selected_date: formattedDate,
      selected_time: selectedTime,
      name: formValues.name,
      email: formValues.email,
      mobile: formValues.mobile,
      age: formValues.age,
      concern: formValues.concern
    };

    emailjs.send('service_m6adkcy', 'template_o3n51ef', formData, 'FE_qmfU7NpslQTD_b')
      .then((result) => {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Form submitted successfully!',
        });
      }, (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Form submission failed. Please try again.',
        });
      })
      .finally(() => {
        setIsSubmitting(false); // Re-enable the submit button after operation
      });
  };

  const getFormattedDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date ? date.toLocaleDateString(undefined, options) : '';
  };

  return (
    <div className="contact-container">
      {!showForm ? (
        <>
          <div className="left-section">
            <h2 className='h2-u'>3E Skin Route's Free Skin Consultation</h2>
            <p>Get a step closer to understanding your constantly changing skin and skin needs with our free consultations.</p>
            <p>Please select a date and time for your consultation.</p>
            <div className="call-us">
              <IoTimerSharp className="icon" /> 15 min
            </div>
            <div className="social-icons">
              <FaFacebookF className="icon" />
              <a href='https://www.instagram.com/3e.skinroute/' target='_blank'> <FaInstagram className="icon" /></a>
              <FaLinkedin className="icon" />
            </div>
          </div>

          <div className="calendar-section">
            <div className='d-f'>
              <h2 className='h2-u'>Select a Date & Time {new Date().getFullYear()}</h2>

              <div className="days-of-week">
                {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => (
                  <div key={index} className="day-name">{day}</div>
                ))}
              </div>

              <div className="calendar">
                {dates.map((date, index) => {
                  const today = new Date();
                  const isPastDate = date < today.setHours(0, 0, 0, 0); // Check if the date is a past date

                  return (
                    <div
                      key={index}
                      className={`date ${isPastDate ? 'disabled' : ''} ${selectedDate && selectedDate.toDateString() === date.toDateString() ? 'selected' : ''}`}
                      onClick={() => !isPastDate && handleDateChange(date)} // Disable click for past dates
                    >
                      {date.getDate()}
                    </div>
                  );
                })}
              </div>
            </div>

            {selectedDate && (
              <div className="time-section-horizontal">
                <h2 className='h2-u'>Select Time</h2>
                {times.map((time, index) => (
                  <div key={index}>
                    {selectedTime === time ? (
                      <button className="next-button" onClick={handleNextClick}>
                        Next
                      </button>
                    ) : (
                      <div
                        className={`time-slot ${selectedTime === time ? 'selected' : ''}`}
                        onClick={() => handleTimeSelect(time)}
                      >
                        {time}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="form-container">
          <div className="left-section">
            <h2 className='h2-u'>3E Skin Route's Free Skin Consultation</h2>
            <p>Get a step closer to understanding your constantly changing skin and skin needs with our free consultations. Our experts can offer tailored skincare advice in 15 minute sessions.</p>
            <p>Please Fill the Required input fields </p>
            <div className="selected-details">
              <p><strong>Date:</strong> {getFormattedDate(selectedDate)}</p>
              <p><strong>Time:</strong> {selectedTime}</p>
            </div>
            <div className="call-us">
              <IoTimerSharp className="icon" /> 15 min
            </div>
            <div className="social-icons">
             <a href='#' target='_blank'> <FaFacebookF className="icon" /></a>

             <a href='https://www.instagram.com/3e.skinroute/' target='_blank'> <FaInstagram className="icon" /></a> 
             <a href='#' target='_blank'>  <FaLinkedin className="icon" /></a> 
            </div>
          </div>

          <div className="form-section">
          <form onSubmit={handleFormSubmit}>
              <h3 className='h2-u'>Fill in Your Details</h3>
              <input type="hidden" name="selected_date" value={selectedDate} />
              <input type="hidden" name="selected_time" value={selectedTime} />
              
              <div className='fff'>
                <label>
                  Name:
                  <input
                    type="text"
                    name="name"
                    value={formValues.name}
                    onChange={handleInputChange}
                    autoComplete='off'
                    required
                  />
                  {formErrors.name && <p className="error">{formErrors.name}</p>}
                </label>
                <label>
                  Email:
                  <input
                    type="email"
                    name="email"
                    autoComplete='off'
                    value={formValues.email}
                    onChange={handleInputChange}
                    required
                  />
                  {formErrors.email && <p className="error">{formErrors.email}</p>}
                </label>
              </div>

              <div className='fff'>
                <label>
                  Mobile:
                  <input
                    type="tel"
                    autoComplete='off'
                    name="mobile"
                    value={formValues.mobile}
                    onChange={handleInputChange}
                    required
                  />
                  {formErrors.mobile && <p className="error">{formErrors.mobile}</p>}
                </label>
                <label>
                  Age:
                  <input
                    type="number"
                    name="age"
                    autoComplete='off'
                    value={formValues.age}
                    onChange={handleInputChange}
                    required
                  />
                  {formErrors.age && <p className="error">{formErrors.age}</p>}
                </label>
              </div>

              <label>
                Your Concern:
                <textarea
                  name="concern"
                  rows="4"
                  autoComplete='off'
                  value={formValues.concern}
                  onChange={handleInputChange}
                  required
                ></textarea>
                {formErrors.concern && <p className="error">{formErrors.concern}</p>}
              </label>
              
              <button type="submit" className='submit-button' disabled={isSubmitting}>Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
     
  );
};

export default Contact;

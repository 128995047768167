import React, { useEffect } from 'react';
import '../Raw/Raw.css';

const Raw = () => {
    useEffect(() => {
      const cards = document.querySelectorAll('.card');
  
      // Apply a sequential delay to each card for animation
      cards.forEach((card, index) => {
        const delay = index * 0.5; // Adjust delay for sequential animation
        card.style.animation = `changeColor 2s ease-in-out ${delay}s infinite, returnToWhite 2s ease-in-out ${delay + 2}s infinite`;
      });
    }, []);
    const cardTexts = [
        'Truth 1',
        'Truth 2',
        'Truth 3',
        'Truth 4',
        'Truth 5',
        'Truth 6',
        'Truth 7',
        'Truth 8',
        'Truth 9',
        'Truth 10'
      ];
  
    return (
      <section className="raw">
        <h2> The Raw Truth</h2>
        <div className="card-grid">
        {cardTexts.map((text, index) => (
          <div key={index} className="card">
            <h1>{text}</h1>
          </div>
        ))}
      </div>
      </section>
    );
  };
  
  export default Raw;
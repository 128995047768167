import React,{useState,useEffect} from 'react';
import '../Move/Move.css';

const Move = () => {
  const data2 = [
    { id: 1, image: 'm1.jpg' },
    { id: 2, image: 'm2.jpg' },
    { id: 3, image: 'm3.jpg' }
  ];
  const data = [
    { id: 1, image: 'm4.jpg' },
    { id: 2, image: 'm5.jpg' },
    { id: 3, image: 'm6.jpg' }
  ];

  const rightMoveData = [
    {
      id: 1,
      title: 'Best for regular makeup routine',
      description1: 'Skin Friendly',
      description2: 'Curabitur vitae nunc sed velit egestas. Cursus sit amet dictum sit amet. Cras adipiscing enim eu turpis Cursus sit amet dictum sit amet. Cras adipiscing enim eu turpis egestas pretium aenean.'
    },
    {
      id: 2,
      title: 'Enhance your beauty everyday',
      description1: 'Natural Radiance',
      description2: 'Curabitur vitae nunc sed velit egestas. Cursus sit amet dictum sit amet. Cras adipiscing enim eu turpis Cursus sit amet dictum sit amet. Cras adipiscing enim eu turpis egestas pretium aenean.'
    },
    {
      id: 3,
      title: 'Unique Chemical Free Cosmetics',
      description1: 'Chemical Free',
      description2: 'Curabitur vitae nunc sed velit egestas. Cursus sit amet dictum sit amet. Cras adipiscing enim eu turpis Cursus sit amet dictum sit amet. Cras adipiscing enim eu turpis egestas pretium aenean.'
    }
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        (prevIndex + 1) % rightMoveData.length
      );
    }, 5000); // Change item every 5 seconds

    return () => clearInterval(interval);
  }, [rightMoveData.length]);

  const currentItem = rightMoveData[currentIndex];
  return (
    <>
    <div className="move-container">
      <div className="left-move">
        <div className="image-list top-to-bottom">
          {data.map(item => (
            <div key={item.id} className="image-card">
              <img src={item.image} alt={`Image ${item.id}`} />
            </div>
          ))}
        </div>
        <div className="image-list2 bottom-to-top">
          {data2.map(item => (
            <div key={item.id} className="image-card">
              <img src={item.image} alt={`Image ${item.id}`} />
            </div>
          ))}
        </div>
      </div>
      <div className="right-move">
        <div className="right-move-item">
        <p className='p2'>{currentItem.description1}</p>
          <h1>{currentItem.title}</h1>
         
          <p>{currentItem.description2}</p>
          <button className='button-17'>Shop Now</button>
        </div>
      </div>
      </div>
      </>
  );
};

export default Move;

// src/components/CardDetail.js
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import "../About/CardDetails.css";
import Ingredents from "../Ingredents/Ingredents";

const CardDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cardData } = location.state || {};

  // Handle case when no card data is passed
  if (!cardData) {
    return (
      <div className="card-detail">
        <h2>Card Details Not Found</h2>
        <button onClick={() => navigate('/about')}>Go Back</button>
      </div>
    );
  }

  return (
    <>
      <div className="card-detail">
        <button className="back-button2" onClick={() => navigate('/about')}>
          &larr; Back to About
        </button>
        <h2 className='title-cardd'>{cardData.title}</h2>
        <img src={`/${cardData.image}`} alt={cardData.title} className="detail-image" />
        <ReactMarkdown className="card-content1">{cardData.content}</ReactMarkdown>
      </div>
      <Ingredents />
    </>
  );
};

export default CardDetail;

import React, { useState } from 'react';
import '../Shop/Shop.css'
const Shop = () => {
  const minPrice = 0;
  const maxPrice = 10000;

  const shopData = {
    filters: {
      productTypes: [
        { id: 1, name: 'Cleaning' },
        { id: 2, name: 'Exfoliators' },
        { id: 3, name: 'Masks' },
        { id: 4, name: 'Serums & Treatments' },
        { id: 5, name: 'Moisturizers & Oils' },
        { id: 6, name: 'Ritual Sets' },
        { id: 7, name: 'Face Care' },
        { id: 8, name: 'Body Care' }
      ],
      skinMoodTypes: [
        { id: 1, name: 'normal skin mood' },
        { id: 2, name: 'combination skin mood' },
        { id: 3, name: 'dry skin mood' },
        { id: 4, name: 'oily skin mood' },
        { id: 5, name: 'sentitised & sensitive skin mood' }
      ]
    },
    products: [
      {
        id: 1,
        image: 'p.jpg',
        title: 'Purifying Cleanser',
        description: 'A gentle daily cleanser that purifies and refreshes the skin.',
        price: 899,
        size: '150 ml',
        emi: 'No Cost EMI Available',
        rating: 4.5,
        reviews: 120,
        type: 'Cleaning',
        skinMood: 'normal skin mood'
      },
      {
        id: 2,
        image: 'p.jpg',
        title: 'Exfoliating Scrub',
        description: 'A powerful exfoliator that removes dead skin cells.',
        price: 1299,
        size: '100 ml',
        emi: 'No Cost EMI Available',
        rating: 4.8,
        reviews: 85,
        type: 'Exfoliators',
        skinMood: 'combination skin mood'
      },
      {
        id: 3,
        image: 'p.jpg',
        title: 'Hydrating Face Mask',
        description: 'A hydrating face mask that revitalizes and replenishes.',
        price: 1499,
        size: '75 ml',
        emi: 'No Cost EMI Available',
        rating: 4.6,
        reviews: 60,
        type: 'Masks',
        skinMood: 'dry skin mood'
      },
      {
        id: 4,
        image: 'p.jpg',
        title: 'Vitamin C Serum',
        description: 'A brightening serum that helps to reduce dark spots.',
        price: 1999,
        size: '30 ml',
        emi: 'No Cost EMI Available',
        rating: 4.7,
        reviews: 200,
        type: 'Serums & Treatments',
        skinMood: 'oily skin mood'
      },
      {
        id: 5,
        image: 'p.jpg',
        title: 'Moisturizing Lotion',
        description: 'A lightweight lotion that provides deep hydration .',
        price: 999,
        size: '200 ml',
        emi: 'No Cost EMI Available',
        rating: 4.3,
        reviews: 150,
        type: 'Moisturizers & Oils',
        skinMood: 'sentitised & sensitive skin mood'
      },
      {
        id: 6,
        image: 'p.jpg',
        title: 'Ritual Skin Care Set',
        description: 'A complete skincare ritual set designed to rejuvenate .',
        price: 2999,
        size: '500ml',
        emi: 'No Cost EMI Available',
        rating: 4.9,
        reviews: 90,
        type: 'Ritual Sets',
        skinMood: 'normal skin mood'
      },
      {
        id: 7,
        image: 'p.jpg',
        title: 'Facial Care ',
        description: 'An essential kit for a comprehensive face care.',
        price: 1799,
        size: '150ml',
        emi: 'No Cost EMI Available',
        rating: 4.6,
        reviews: 130,
        type: 'Face Care',
        skinMood: 'combination skin mood'
      },
      {
        id: 8,
        image: 'p.jpg',
        title: 'Body Butter',
        description: 'A rich body butter that nourishes and hydrates .',
        price: 799,
        size: '250 ml',
        emi: 'No Cost EMI Available',
        rating: 4.4,
        reviews: 70,
        type: 'Body Care',
        skinMood: 'dry skin mood'
      },
      {
        id: 9,
        image: 'p.jpg',
        title: 'Body Butter',
        description: 'A rich body butter that nourishes and hydrates .',
        price: 799,
        size: '250 ml',
        emi: 'No Cost EMI Available',
        rating: 4.4,
        reviews: 70,
        type: 'Body Care',
        skinMood: 'dry skin mood'
      }
    ]
  };

  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [selectedSkinMoodTypes, setSelectedSkinMoodTypes] = useState([]);
  const [priceRange, setPriceRange] = useState(maxPrice);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const filteredProducts = shopData.products.filter(product => {
    const productTypeMatch = selectedProductTypes.length === 0 || selectedProductTypes.includes(product.type);
    const skinMoodMatch = selectedSkinMoodTypes.length === 0 || selectedSkinMoodTypes.includes(product.skinMood);
    const priceMatch = product.price <= priceRange;
    return productTypeMatch && skinMoodMatch && priceMatch;
  });

  const handleProductTypeChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedProductTypes([...selectedProductTypes, value]);
    } else {
      setSelectedProductTypes(selectedProductTypes.filter(type => type !== value));
    }
  };

  const handleSkinMoodChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedSkinMoodTypes([...selectedSkinMoodTypes, value]);
    } else {
      setSelectedSkinMoodTypes(selectedSkinMoodTypes.filter(mood => mood !== value));
    }
  };

  return (
    <div className="shop-container">
       <aside className={`filter-sidebar ${sidebarCollapsed ? 'collapsed' : ''}`}>
        <button className="sidebar-toggle" onClick={() => setSidebarCollapsed(!sidebarCollapsed)}>
          {sidebarCollapsed ? '✖' : '☰'}
        </button>
        <h3><i className="fa fa-filter"></i> Filters</h3>

        {/* Price Filter */}
        <h4>Price</h4>
        <div className='pi'>
          <div className="price-range-info">
            <span>{`₹ ${minPrice}`}</span>
            <span>{`₹ ${priceRange}`}</span>
          </div>
          <input
            type="range"
            min={minPrice}
            max={maxPrice}
            value={priceRange}
            onChange={(e) => setPriceRange(e.target.value)}
          />
        </div>

        {/* Product Type Filter */}
        <h4>Product Type</h4>
        {shopData.filters.productTypes.map(type => (
          <div key={type.id}>
            <input
              type="checkbox"
              value={type.name}
              onChange={handleProductTypeChange}
            />
            <label>{type.name}</label>
          </div>
        ))}

        {/* Skin/Mood Type Filter */}
        <h4>Skin/Mood Type</h4>
        {shopData.filters.skinMoodTypes.map(mood => (
          <div key={mood.id}>
            <input
              type="checkbox"
              value={mood.name}
              onChange={handleSkinMoodChange}
            />
            <label>{mood.name}</label>
          </div>
        ))}
      </aside>

      <div className="product-grid">
        {filteredProducts.map(product => (
          <div className="product-card1" key={product.id}>
          <div className='img-div'>
            <img src={product.image} alt={product.title} className="product-image1" />
            </div>
            <h3 className="title-p1">{product.title}</h3>
            <p className="description1">{product.description}</p>
            <div className="fl-p">
              <div className="price1">{`₹ ${product.price}`}</div>
              <p className="p-size1">{product.size}</p>
            </div>
           
            <p className="star1">{`⭐ ${product.rating} stars | ${product.reviews} reviews`}</p>
            <button className="view-product1">SHOP NOW</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Shop;















import React from "react";
import {
  FaLeaf,
  FaBolt,
  FaTint,
  FaFlask,
  FaBan,
  FaCheckCircle,
} from "react-icons/fa"; // Updated icons
import { FaEnvelope, FaPhone } from 'react-icons/fa';
import { GiHairStrands } from "react-icons/gi";
import "../About/Story.css";
import { Link } from "react-router-dom";
const cardData = [
  {
    id: 1,
    title: "Cruelty Free",
    description:
      "Our products are never tested on animals, ensuring ethical practices in all we do.",
    icon: <GiHairStrands />,
  },
  {
    id: 2,
    title: "Fast Absorbing",
    description:
      "Formulated to be lightweight and fast-absorbing, providing quick relief and hydration.",
    icon: <FaBolt />,
  },
  {
    id: 3,
    title: "Sulphate Free",
    description:
      "We avoid harsh chemicals like sulphates, ensuring gentle care for your skin.",
    icon: <FaTint />,
  },
  {
    id: 4,
    title: "Natural Ingredients",
    description:
      "Only the purest, natural ingredients are used, giving you healthy, glowing skin.",
    icon: <FaLeaf />,
  },
  {
    id: 5,
    title: "No SLS",
    description:
      "Our products are free from Sodium Lauryl Sulfate (SLS), making them safe for sensitive skin.",
    icon: <FaBan />,
  },
  {
    id: 6,
    title: "Clinically Proven",
    description:
      "All our formulas are clinically tested to provide results you can trust.",
    icon: <FaCheckCircle />,
  },
];

const Story = () => {
    const calladmin = () => {
        const phoneNumber = 'tel:+91 0000000000'; 
        window.open(phoneNumber, '_self');
      };
      
  const handleClick = () => {
    
    window.open(`mailto:${'Info@xyz.com'}`);
  };
  return (
    <>
    <div className="full-story">
    <div className="story-section">
      <div className="ll-s">
        <h2 className="text" data-aos="fade-down">Our Story</h2>
        <p className="pp" data-aos="fade-down">
          At 3E Skin Route, we blend advanced skincare solutions with
          personalized consulting to reveal your best skin. Our mission is to
          empower you with tailored care and expert advice. Discover the path to
          radiant, healthy skin with us. Your journey to beauty starts here.
        </p>
        <div className="cards-grid-s">
          {cardData.map((card) => (
            <div className="card-s" data-aos="zoom-in" key={card.id}>
              <div className="card-content-s">
                <h3>{card.title}</h3>
                <p>{card.description}</p>
              </div>
              <div className="icon-s">{card.icon}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="rr-s">
        <img src="beauty.jpg" data-aos="fade-left" alt="Unique" />
      </div>
      
    </div>



    




    <section className="email-section">
      <div className="contact-info">
        <div className="email-info">
          <div className="contact-text"  onClick={handleClick}>
            <h2>Email Us</h2>
            <p>contact@example.com</p>
          </div>
          <div className="contact-logo">
            <FaEnvelope size={40} color="rgb(0 78 78)" />
          </div>
        </div>
        <div className="call-info" onClick={calladmin}>
          <div className="contact-text">
            <h2>Call Us</h2>
            <p>+91 123 456 7890</p>
          </div>
          <div className="contact-logo">
            <FaPhone size={40} color="rgb(0 78 78)" />
          </div>
        </div>
      </div>
      <div className="contact-button">
      <Link to="/contact">
        <button>Contact Us</button>
        </Link>
      </div>
    </section>
    </div>

    <div className="gallery-container">
    
      <div className="gallery-grid">
        <div className="gallery-card card-1" data-aos="fade-right">
          <img src="gs1.jpg" alt="Gallery Image 1" />
        </div>
        <div className="gallery-card card-2" data-aos="fade-down">
          <img src="gs2.jpg" alt="Gallery Image 2" />
        </div>
        <div className="gallery-card card-3" data-aos="fade-left">
          <img src="gs3.jpg" alt="Gallery Image 3" />
        </div>
        <div className="gallery-card card-4" data-aos="fade-right">
          <img src="gs4.jpg" alt="Gallery Image 4" />
        </div>
        <div className="gallery-card card-5" data-aos="fade-up">
          <img src="gs5.jpg" alt="Gallery Image 5" />
        </div>
        <div className="gallery-card card-6" data-aos="fade-left">
          <img src="gs6.jpg" alt="Gallery Image 6" />
        </div>
      </div>
    </div>
    </>
  );
};

export default Story;

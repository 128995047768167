import React, { useState, useEffect } from "react";
import "../Slider/Slider.css";

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    {
      image:
        "https://img.freepik.com/premium-photo/woman39s-face-partially-hidden-by-lush-green-leaves_239711-10922.jpg?w=996",
      heading: "Are You Ready For The Glowing Skin?",
      description: "Nourish Your Skin with Natures Best",
    },
    {
      image:
        "https://img.freepik.com/premium-photo/woman-pouring-lotion-into-hand-cosmetic-product-branding-mockup_1270664-24366.jpg?w=826",
      heading: "Elevate Your Beauty Routine",
      description: "Luxury Products for Effortless Elegance",
    },
    {
      image:
        "https://img.freepik.com/premium-photo/woman-is-sitting-tree-with-her-eyes-closed_248459-22478.jpg?w=996",
      heading: "Your Journey To Skin Care?",
      description: "Discover the Power of Radiant Skin Care",
    },
  ];

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 4000); // 3 seconds for each slide
    return () => clearInterval(slideInterval);
  }, [slides.length]);

  return (
    <div className="slider">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`slide ${index === currentSlide ? "active" : ""}`}
          style={{ backgroundImage: `url(${slide.image})` }}
        >
       <div class="block block-left"></div>
  <div class="block block-middle"></div>
  <div class="block block-right"></div>
          <div className="overlay">
            <h2>{slide.heading}</h2>
            <p>{slide.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Slider;

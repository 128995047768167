import React, { useState } from "react";
import "../Ingredents/OurIncredients.css"; 

const ingredientsData = {
    A: ["Aloe Vera", "Apple Extract", "Avocado Oil", "Almond Oil", "Apricot Kernel"],
    B: ["Basil", "Banana Extract", "Bentonite Clay", "Bergamot Oil", "Beeswax"],
    C: ["Coconut Oil", "Cucumber Extract", "Chamomile", "Calendula", "Cocoa Butter"],
    D: ["Dandelion Root", "Dead Sea Salt", "Dragonfruit Extract", "Daisy Flower", "DHA (Dihydroxyacetone)"],
    E: ["Elderberry", "Eucalyptus Oil", "Egg White", "Evening Primrose Oil", "Emu Oil"],
    F: ["Fennel Seed", "Frankincense Oil", "Fruits Enzyme", "Flaxseed Oil", "Fucus (Seaweed)"],
    G: ["Green Tea", "Grape Seed Oil", "Goji Berry", "Ginger Root", "Geranium Oil"],
    H: ["Honey", "Hemp Seed Oil", "Hibiscus Extract", "Hyaluronic Acid", "Horsetail Extract"],
    I: ["Ivy Extract", "Inositol", "Irish Moss", "Iceland Moss", "Isopropyl Myristate"],
    J: ["Jojoba Oil", "Jasmine Extract", "Juniper Berry", "Japanese Green Tea", "Jicama Root"],
    K: ["Kiwi Extract", "Kelp", "Kumquat Extract", "Kakadu Plum", "Kokum Butter"],
    L: ["Lavender Oil", "Lemon Balm", "Licorice Root", "Lime Extract", "Linseed Oil"],
    M: ["Mango Butter", "Moringa Oil", "Mulberry Extract", "Milk Thistle", "Manuka Honey"],
    N: ["Neem Oil", "Neroli Oil", "Noni Fruit", "Niacinamide", "Nutmeg"],
    O: ["Olive Oil", "Orange Peel", "Oat Extract", "Oregano Oil", "Orchid Extract"],
    P: ["Papaya Enzyme", "Pineapple Extract", "Pumpkin Seed Oil", "Peach Kernel", "Pomegranate Extract"],
    Q: ["Quinoa Extract", "Quince Seed", "Queen Anne's Lace", "Quillaja Extract", "Quassia Bark"],
    R: ["Rosehip Oil", "Rice Bran Oil", "Raspberry Seed Oil", "Rose Water", "Rhassoul Clay"],
    S: ["Shea Butter", "Safflower Oil", "Sea Buckthorn", "Sandalwood Oil", "Soybean Oil"],
    T: ["Tea Tree Oil", "Turmeric", "Tamanu Oil", "Tomato Extract", "Tocopherol (Vitamin E)"],
    U: ["Urea", "Ulmus Extract", "Ugli Fruit", "Umbelliferae Oil", "Uva Ursi"],
    V: ["Vanilla Extract", "Vitamin C", "Vetiver Oil", "Vegetable Glycerin", "Violet Leaf"],
    W: ["Witch Hazel", "Watermelon Seed Oil", "Willow Bark", "Walnut Shell", "White Tea"],
    X: ["Xanthan Gum", "Xylitol", "Ximenia Seed Oil", "Xylocarpa Extract", "Xerophyte Plant Extract"],
    Y: ["Yarrow Extract", "Yucca Root", "Ylang Ylang Oil", "Yellow Dock Root", "Yerba Mate"],
    Z: ["Zinc Oxide", "Zedoary Root", "Zostera Marina", "Zanthoxylum Extract", "Zeolite Clay"]
  };
  

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

const OurIngredients = () => {
  const [selectedLetter, setSelectedLetter] = useState("A");

  const handleLetterClick = (letter) => {
    setSelectedLetter(letter);
  };

  return (
    <div className="ingredients-section">
      <h1 className="o-head">Our Ingredients</h1>
      <div className="alphabet-container">
        {alphabet.map((letter) => (
          <button
            key={letter}
            className={`alphabet-btn ${selectedLetter === letter ? "active" : ""}`}
            onClick={() => handleLetterClick(letter)}
          >
            {letter}
          </button>
        ))}
      </div>

      <div className="ingredients-grid">
        {ingredientsData[selectedLetter]?.map((ingredient, index) => (
          <div key={index} className="ingredient-card">
            <p>{ingredient}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurIngredients;

import React, { useState } from "react";
import ProductCard from "../Products/ProductCard";
import "../Products/Products.css";

const Product = ({ selectedCategory }) => {
  const [visibleProducts, setVisibleProducts] = useState(6);

  const showMoreProducts = () => {
    setVisibleProducts(visibleProducts + 3);
  };

  const allProducts = [
    // Facial Care Products
    {
      id: 1,
      title: "Regenerating Serum",
      description: "An ultimate treatment for better aging!",
      price: 1600,
      size: "10 ml",
      rating: 49,
      reviews: 49,
      emi: "₹533/Month | 0% EMI",
      image: "p.jpg",
      category: "Face Serum",
    },
    {
      id: 2,
      title: "AHA Power Peel",
      description: "For instant gratification and smooth exfoliation!",
      price: 2200,
      size: "50 gm",
      rating: 66,
      reviews: 66,
      emi: "₹733/Month | 0% EMI",
      image: "p.jpg",
      category: "Face Mask",
    },
    {
      id: 3,
      title: "Hydrating Cleanser",
      description: "Gentle cleanser for deep hydration.",
      price: 1200,
      size: "100 ml",
      rating: 55,
      reviews: 55,
      emi: "₹400/Month | 0% EMI",
      image: "p.jpg",
      category: "Face Cleaning",
    },
    {
      id: 4,
      title: "Revitalizing Toner",
      description: "Balances skin pH and tightens pores.",
      price: 1400,
      size: "120 ml",
      rating: 60,
      reviews: 60,
      emi: "₹467/Month | 0% EMI",
      image: "p.jpg",
      category: "Face Toner",
    },
    {
      id: 5,
      title: "Deep Moisture Cream",
      description: "Provides intense moisture and nourishment.",
      price: 1800,
      size: "50 gm",
      rating: 70,
      reviews: 70,
      emi: "₹600/Month | 0% EMI",
      image: "p.jpg",
      category: "Face Moisturizer",
    },
    {
      id: 6,
      title: "Nourishing Face Oil",
      description: "Rich oil blend for glowing skin.",
      price: 2000,
      size: "30 ml",
      rating: 65,
      reviews: 65,
      emi: "₹667/Month | 0% EMI",
      image: "p.jpg",
      category: "Face Oil",
    },
    {
      id: 7,
      title: "Exfoliating Scrub",
      description: "Gently exfoliates for smooth skin.",
      price: 1300,
      size: "75 gm",
      rating: 58,
      reviews: 58,
      emi: "₹433/Month | 0% EMI",
      image: "p.jpg",
      category: "Face Scrub",
    },
    {
      id: 8,
      title: "Brightening Peel",
      description: "Lightens dark spots and brightens complexion.",
      price: 2500,
      size: "30 ml",
      rating: 72,
      reviews: 72,
      emi: "₹833/Month | 0% EMI",
      image: "p.jpg",
      category: "Face Peel",
    },
    {
      id: 9,
      title: "Rejuvenating Face Gel",
      description: "Cool gel for refreshing your skin.",
      price: 1100,
      size: "100 ml",
      rating: 52,
      reviews: 52,
      emi: "₹367/Month | 0% EMI",
      image: "p.jpg",
      category: "Face Gel",
    },
    {
      id: 10,
      title: "Hydrating Face Mask",
      description: "Intense hydration for dry skin.",
      price: 1900,
      size: "50 gm",
      rating: 68,
      reviews: 68,
      emi: "₹633/Month | 0% EMI",
      image: "p.jpg",
      category: "Face Mask",
    },
    {
      id: 11,
      title: "Soothing Face Cleanser",
      description: "Gentle formula for sensitive skin.",
      price: 1300,
      size: "100 ml",
      rating: 60,
      reviews: 60,
      emi: "₹433/Month | 0% EMI",
      image: "p.jpg",
      category: "Face Cleaning",
    },
    {
      id: 12,
      title: "Anti-Aging Face Cream",
      description: "Reduces fine lines and wrinkles.",
      price: 2300,
      size: "50 gm",
      rating: 75,
      reviews: 75,
      emi: "₹767/Month | 0% EMI",
      image: "p.jpg",
      category: "Face Moisturizer",
    },
  
    // Hair Care Products
    {
      id: 13,
      title: "Revitalizing Shampoo",
      description: "Cleanses and revitalizes hair.",
      price: 1500,
      size: "200 ml",
      rating: 58,
      reviews: 58,
      emi: "₹500/Month | 0% EMI",
      image: "p.jpg",
      category: "Shampoo",
    },
    {
      id: 14,
      title: "Nourishing Conditioner",
      description: "Conditions and smoothens hair.",
      price: 1600,
      size: "200 ml",
      rating: 62,
      reviews: 62,
      emi: "₹533/Month | 0% EMI",
      image: "p.jpg",
      category: "Conditioner",
    },
    {
      id: 15,
      title: "Strengthening Hair Serum",
      description: "Fortifies and strengthens hair.",
      price: 1800,
      size: "50 ml",
      rating: 65,
      reviews: 65,
      emi: "₹600/Month | 0% EMI",
      image: "p.jpg",
      category: "Hair Serum",
    },
    {
      id: 16,
      title: "Hydrating Hair Mask",
      description: "Deeply moisturizes and repairs hair.",
      price: 1900,
      size: "100 gm",
      rating: 70,
      reviews: 70,
      emi: "₹633/Month | 0% EMI",
      image: "p.jpg",
      category: "Hair Mask",
    },
    {
      id: 17,
      title: "Daily Hair Oil",
      description: "Provides daily nourishment.",
      price: 2000,
      size: "100 ml",
      rating: 68,
      reviews: 68,
      emi: "₹667/Month | 0% EMI",
      image: "p.jpg",
      category: "Hair Oil",
    },
    {
      id: 18,
      title: "Volumizing Hair Spray",
      description: "Adds volume and body to hair.",
      price: 1400,
      size: "200 ml",
      rating: 60,
      reviews: 60,
      emi: "₹467/Month | 0% EMI",
      image: "p.jpg",
      category: "Hair Spray",
    },
    {
      id: 19,
      title: "Firming Hair Gel",
      description: "Provides strong hold and shine.",
      price: 1200,
      size: "150 ml",
      rating: 55,
      reviews: 55,
      emi: "₹400/Month | 0% EMI",
      image: "p.jpg",
      category: "Hair Gel",
    },
    {
      id: 20,
      title: "Repairing Hair Treatment",
      description: "Repairs and revitalizes damaged hair.",
      price: 2100,
      size: "50 ml",
      rating: 70,
      reviews: 70,
      emi: "₹700/Month | 0% EMI",
      image: "p.jpg",
      category: "Hair Treatment",
    },
    {
      id: 21,
      title: "Restorative Hair Revitalizer",
      description: "Restores shine and strength to hair.",
      price: 2300,
      size: "50 ml",
      rating: 75,
      reviews: 75,
      emi: "₹767/Month | 0% EMI",
      image: "p.jpg",
      category: "Hair Revitalizer",
    },
    {
      id: 22,
      title: "Gentle Hair Cleanser",
      description: "Mild cleanser for everyday use.",
      price: 1400,
      size: "250 ml",
      rating: 60,
      reviews: 60,
      emi: "₹467/Month | 0% EMI",
      image: "p.jpg",
      category: "Hair Cleanser",
    },
    {
      id: 23,
      title: "Thickening Hair Conditioner",
      description: "Thickens and adds volume to hair.",
      price: 1700,
      size: "200 ml",
      rating: 62,
      reviews: 62,
      emi: "₹567/Month | 0% EMI",
      image: "p.jpg",
      category: "Hair Thickener",
    },
  
    // Body Care Products
    {
      id: 24,
      title: "Exfoliating Body Scrub",
      description: "Removes dead skin cells and smoothens skin.",
      price: 1300,
      size: "200 gm",
      rating: 55,
      reviews: 55,
      emi: "₹433/Month | 0% EMI",
      image: "p.jpg",
      category: "Body Scrub",
    },
    {
      id: 25,
      title: "Hydrating Body Lotion",
      description: "Deeply moisturizes and nourishes the skin.",
      price: 1500,
      size: "250 ml",
      rating: 60,
      reviews: 60,
      emi: "₹500/Month | 0% EMI",
      image: "p.jpg",
      category: "Body Lotion",
    },
    {
      id: 26,
      title: "Revitalizing Body Wash",
      description: "Energizing wash for a fresh feeling.",
      price: 1400,
      size: "250 ml",
      rating: 58,
      reviews: 58,
      emi: "₹467/Month | 0% EMI",
      image: "p.jpg",
      category: "Body Wash",
    },
    {
      id: 27,
      title: "Soothing Body Oil",
      description: "Relieves and soothes tired skin.",
      price: 1700,
      size: "100 ml",
      rating: 62,
      reviews: 62,
      emi: "₹567/Month | 0% EMI",
      image: "p.jpg",
      category: "Body Oil",
    },
    {
      id: 28,
      title: "Cooling Body Gel",
      description: "Provides instant cooling and refreshment.",
      price: 1600,
      size: "150 ml",
      rating: 65,
      reviews: 65,
      emi: "₹533/Month | 0% EMI",
      image: "p.jpg",
      category: "Body Gel",
    },
    {
      id: 29,
      title: "Clarifying Body Mist",
      description: "Refreshing mist for a burst of freshness.",
      price: 1200,
      size: "150 ml",
      rating: 55,
      reviews: 55,
      emi: "₹400/Month | 0% EMI",
      image: "p.jpg",
      category: "Body Mist",
    },
    {
      id: 30,
      title: "Smoothing Body Butter",
      description: "Rich butter for ultra-moisturization.",
      price: 1800,
      size: "200 gm",
      rating: 68,
      reviews: 68,
      emi: "₹600/Month | 0% EMI",
      image: "p.jpg",
      category: "Body Butter",
    },
    {
      id: 31,
      title: "Nourishing Body Balm",
      description: "Deeply nourishes and repairs dry skin.",
      price: 1900,
      size: "100 gm",
      rating: 70,
      reviews: 70,
      emi: "₹633/Month | 0% EMI",
      image: "p.jpg",
      category: "Body Balm",
    },
    {
      id: 32,
      title: "Firming Body Cream",
      description: "Helps firm and tone the skin.",
      price: 2000,
      size: "150 ml",
      rating: 72,
      reviews: 72,
      emi: "₹667/Month | 0% EMI",
      image: "p.jpg",
      category: "Body Cream",
    },
    {
      id: 33,
      title: "Protective Sunscreen",
      description: "Broad-spectrum SPF protection.",
      price: 2100,
      size: "100 ml",
      rating: 75,
      reviews: 75,
      emi: "₹700/Month | 0% EMI",
      image: "p.jpg",
      category: "Sunscreen",
    },
    {
      id: 34,
      title: "Toning Body Lotion",
      description: "Tones and revitalizes skin.",
      price: 1300,
      size: "250 ml",
      rating: 55,
      reviews: 55,
      emi: "₹433/Month | 0% EMI",
      image: "p.jpg",
      category: "Body Lotion",
    },
    {
      id: 35,
      title: "Body Whitening Cream",
      description: "Brightens and evens out skin tone.",
      price: 1800,
      size: "100 gm",
      rating: 60,
      reviews: 60,
      emi: "₹600/Month | 0% EMI",
      image: "p.jpg",
      category: "Body Cream",
    },
  ];
  
  
  
  

  const filteredProducts = allProducts.filter(
    (product) => product.category === selectedCategory
  );

  return (
    <section className="products-section">
      <h2>
        {selectedCategory ? `Products for ${selectedCategory}` : "no-records"}
      </h2>
      <div className="products-grid">
        {filteredProducts.slice(0, visibleProducts).map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
      {visibleProducts < filteredProducts.length && (
        <div className="see-more-container">
          <button className="see-more-btn" onClick={showMoreProducts}>
            See More
          </button>
        </div>
      )}
    </section>
  );
};

export default Product;

import React from 'react';
import '../Products/ProductCard.css'; 
import { Link } from 'react-router-dom';
const ProductCard = ({ product }) => {
  return (
    <div className="product-card">
      <img src={product.image} alt={product.title} className="product-image" />
      <h3 className='title-p'>{product.title}</h3>
      <p className="description">{product.description}</p>
      <div className='fl-p'><div className="price">{`₹ ${product.price}`}</div>
      <p className='p-size'>{product.size}</p>
      </div>
      <p className="emi">{product.emi}</p>
      <p className='star'>{`⭐ ${product.rating} stars | ${product.reviews} reviews`}</p>
     <Link to="/contact"> <button className="view-product">SHOP NOW</button></Link>
    </div>
  );
};

export default ProductCard;

import React, { useState, useEffect } from 'react';
import '../Brands/Brands.css'; // Assuming this path is correct for your CSS file

const products = [
  {
    price: '₹290',
    name: 'Night Face Moisturizer',
    desc: 'Sed ut perspiciatis unde omnis iste natus error sit, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.',
    img: 'bb1.jpg',
  },
  {
    price: '₹500',
    name: 'Mattifying Face Primer',
    desc: 'Sed ut perspiciatis unde omnis iste natus error sit, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.',
    img: 'bb2.jpg',
  },
  {
    price: '₹300',
    name: 'Liquid Makeup Foundation',
    desc: 'Sed ut perspiciatis unde omnis iste natus error sit, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.',
    img: 'bb3.jpg',
  },
  {
    price: '₹200',
    name: 'Hydrating Dry Shampoo',
    desc: 'Sed ut perspiciatis unde omnis iste natus error sit, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.',
    img: 'bb4.jpg',
  },
  {
    price: '₹150',
    name: 'Instant Dry Nail Polish',
    desc: 'Sed ut perspiciatis unde omnis iste natus error sit, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.',
    img: 'bb5.jpg',
  }
];

const Brands = () => {
  const [selectedProduct, setSelectedProduct] = useState(products[0]);
  const [isZooming, setIsZooming] = useState(false);

  useEffect(() => {
    // Trigger zoom-out effect on image change
    setIsZooming(true);
    const timer = setTimeout(() => setIsZooming(false), 500); // Duration of the animation

    return () => clearTimeout(timer); // Clean up timer when component unmounts or product changes
  }, [selectedProduct]);

  return (
    <div className="brands-container">
      <h2 className="brands-heading">Premium Brands</h2>
      <div className="brands-content">
        {/* Left Side: List of Products */}
        <div className="brands-list">
          {products.map((product, index) => (
            <div
              key={index}
              className={`product-item ${selectedProduct.name === product.name ? 'active' : ''}`}
              onClick={() => setSelectedProduct(product)}
            >
              <div className="product-price">{product.price}</div>
              <div className="product-desc">
                <p>{product.desc}</p>
              </div>
              <h3 className="pnn">{product.name}</h3>
            </div>
          ))}
        </div>

        {/* Right Side: Product Image */}
        <div className="product-image-container">
          <img
            src={selectedProduct.img}
            alt={selectedProduct.name}
            className={`product-image11 ${isZooming ? 'zoom-out' : 'zoom-in'}`} // Switching between zoom-in and zoom-out
          />
        </div>
      </div>
    </div>
  );
};

export default Brands;

import React,{useState} from 'react'
import Slider from '../Slider/Slider'
import Benefits from '../Benifits/Benifits'
import Runner from '../Runner/Runner'
import Seller from '../Seller/Seller'
import Category from '../Products/Category'
import Product from '../Products/Products'
import Review from '../Review/Review'
import Raw from '../Raw/Raw'
import Faq from '../Faq/Faq'
import Slider2 from '../Slider2/Slider2'
import Slider3 from '../Slider3/Slider3'
import Mimg from '../Mimg/Mimg'


const Home = () => {
    const [selectedCategory, setSelectedCategory] = useState('');

    const handleCategoryClick = (categoryName) => {
      setSelectedCategory(categoryName);
    };
  return (
    <>
<Slider/>


<Benefits/>

<Seller/>
<Mimg/>
<Category onCategoryClick={handleCategoryClick} />
<Product selectedCategory={selectedCategory} />
<Review/>
<Raw/>
<Faq/>

    </>
  )
}

export default Home
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../About/About.css";
import Story from "./Story";
import Brands from "../Brands/Brands";

const About = () => {
  const navigate = useNavigate(); // Use useNavigate hook for navigation
  const [selectedCard, setSelectedCard] = useState(null);

  const cards = [
    {
      id: 1,
      category: "Lab Stories",
      title: "Why Diglucosyl Gallic Acid (DGA) is a Skincare Must-Have",
      description:
        "Let’s talk about a powerhouse ingredient that’s making waves. Diglucosyl Gallic Acid (DGA) is a powerful bioengineered ingredient...",
      image: "b1.webp",
      content: `# Why Diglucosyl Gallic Acid (DGA) is a Skincare Must-Have
    
    Ever feel like your skincare routine needs a little extra oomph? Let’s talk about a powerhouse ingredient that’s making waves. Diglucosyl Gallic Acid (DGA).~ by Juhi Dubey
    
    Diglucosyl Gallic Acid, a powerful bioengineered ingredient, offers more than just surface-level benefits. Its ability to visibly brighten the skin and reduce inflammation has made it an award-winning addition to skincare. Originally used in medical settings, this potent active is now being incorporated into products by well-known brands like Sunday Riley, Honest Beauty, and more. Closer home brand Aminu has worked to ensure that this clinical-grade ingredient is not only effective but also safe for everyday use, making it more accessible for those seeking advanced skincare solutions.
    
    ## What Makes DGA a Next-Gen Ingredient?
    
    The ingredient you didn’t know your skin was craving. Whether you’re dealing with stubborn dark spots, or redness that just won’t quit, or you simply want that glowing, even-toned skin, Gallic Acid is about to become your new favorite.
    
    DGA isn’t just another ingredient on the block, it’s a multitasker that works through seven biological pathways reducing oxidative stress, calming down redness, and soothing inflammation while lowering melanin production, and preventing uneven melanin distribution from becoming visible all in one go.
    
    ## The Science Behind
    
    One of the reasons DGA stands out is its versatility. This biotech ingredient gets activated by your skin's microbiome (a fancy way of saying the good bacteria that help keep everything in balance). When it comes to brightening, It's proven to be 60 times more potent than Kojic Acid and four times more potent than Vitamin C. And it doesn't just stop at brightening. It also gives your skin a shield against UV damage so your skin stays safe.
    
    ## Why You NEED Galic Acid in Your Routine
    
    If you’re tired of juggling multiple products to get the results you want, DGA can help simplify things. This powerful ingredient tackles several skin concerns at once, whether it’s dullness, redness, irritation, or uneven tone. It’s like a one-stop solution that fits right into your existing routine, making it perfect for those with sensitive skin.
    
    ## Products to Try
    
    Aminu’s Correcting Concentrate and Copper Peptide Serum are two fantastic products that feature this next-gen active ingredient. Incorporating these into your routine can help you achieve a brighter, more even complexion without irritation.
    
    ---
    
    **Disclaimer**: The opinions expressed in this journal are those of the authors and are for information purposes only and not medical advice. Further, they do not reflect the opinions or views of Aminu Wellness Pvt Ltd or any of its directors. Any content provided by the author(s) are of their opinion and are not intended to malign any religion, ethnic group, club, organization, company, individual, or anyone, or anything.
    
    ## Suggested Reads
    
    - **Why Diglucosyl Gallic Acid (DGA) is a Skincare Must-Have**  
      Ever feel like your skincare routine needs a little extra oomph? Let’s talk about a powerhouse ingredient that’s making waves. Diglucosyl Gallic A...
    
    - **What is Bio-Retinol and What Are Some Known and Effective Bio-Retinols?**  
      Bio-Retinols offer the better-aging benefits of traditional retinol—without the harsh side effects. Gentle yet effective, plant-based ingredien...
    
    - **Why vegan skincare is better?**  
      Have you ever noticed that little squiggly symbol on Aminu's website? It may be small, but it holds power - signifying that you’re helping to save ...
        `,
    },
    {
      id: 2,
      category: "Skin Care",
      title: "What is Bio-Retinol and Known Effective Bio-Retinols?",
      description:
        "Bio-Retinols offer the better-aging benefits of traditional retinol—without the harsh side effects...",
      image: "b2.webp",
      content: `# What is Bio-Retinol and What Are Some Known and Effective Bio-Retinols?
    
    Bio-Retinols offer the better-aging benefits of traditional retinol—without the harsh side effects. Gentle yet effective, plant-based ingredients are revolutionizing the skincare industry by providing similar results with improved tolerance.
    
    ## Benefits of Bio-Retinols
    
    - **Reduced Wrinkles**: Helps in minimizing fine lines and wrinkles.
    - **Improved Skin Texture**: Enhances overall skin smoothness.
    - **Even Skin Tone**: Addresses pigmentation and uneven skin tone.
    - **Anti-Aging**: Promotes collagen production for youthful skin.
    
    ## Popular Bio-Retinol Products
    
    - **Aminu’s Bio-Retinol Serum**: A potent formula that delivers visible results.
    - **Sunday Riley’s Bio Retinol**: Combines the power of bioengineered retinol with other nourishing ingredients.
    
    ---
    
    Stay tuned for more insights into effective skincare ingredients!
        `,
    },
    {
      id: 3,
      category: "We Care",
      title: "Why Vegan Skincare is Better",
      description:
        "Have you ever noticed that little squiggly symbol on Aminu's website? It may be small, but it holds power...",
      image: "b3.webp", // Dummy Image
      content: `# Why Vegan Skincare is Better
    
    Have you ever noticed that little squiggly symbol on Aminu's website? It may be small, but it holds power - signifying that you’re helping to save ...
    
    ## Benefits of Vegan Skincare
    
    - **Cruelty-Free**: No animal testing involved.
    - **Eco-Friendly**: Sustainable and environmentally friendly ingredients.
    - **Gentle on Skin**: Often free from harsh chemicals, making it suitable for sensitive skin.
    
    ## Aminu’s Commitment
    
    At Aminu, we believe in creating products that not only benefit your skin but also the planet. Our vegan skincare line is crafted with care, ensuring that each product is both effective and ethical.
    
    ---
    
    Explore our range of vegan skincare products and join us in making a positive impact!
        `,
    },
    {
      id: 4,
      category: "Lab Stories",
      title: "Why Vegan Skincare is Better",
      description:
        "Have you ever noticed that little squiggly symbol on Aminu's website? It may be small, but it holds power...",
      image: "b3.webp", // Dummy Image
      content: `# Why Vegan Skincare is Better
    
    Have you ever noticed that little squiggly symbol on Aminu's website? It may be small, but it holds power - signifying that you’re helping to save ...
    
    ## Benefits of Vegan Skincare
    
    - **Cruelty-Free**: No animal testing involved.
    - **Eco-Friendly**: Sustainable and environmentally friendly ingredients.
    - **Gentle on Skin**: Often free from harsh chemicals, making it suitable for sensitive skin.
    
    ## Aminu’s Commitment
    
    At Aminu, we believe in creating products that not only benefit your skin but also the planet. Our vegan skincare line is crafted with care, ensuring that each product is both effective and ethical.
    
    ---
    
    Explore our range of vegan skincare products and join us in making a positive impact!
        `,
    },
    {
      id: 5,
      category: "Skin Care",
      title: "Why Vegan Skincare is Better",
      description:
        "Have you ever noticed that little squiggly symbol on Aminu's website? It may be small, but it holds power...",
      image: "b3.webp", // Dummy Image
      content: `# Why Vegan Skincare is Better
    
    Have you ever noticed that little squiggly symbol on Aminu's website? It may be small, but it holds power - signifying that you’re helping to save ...
    
    ## Benefits of Vegan Skincare
    
    - **Cruelty-Free**: No animal testing involved.
    - **Eco-Friendly**: Sustainable and environmentally friendly ingredients.
    - **Gentle on Skin**: Often free from harsh chemicals, making it suitable for sensitive skin.
    
    ## Aminu’s Commitment
    
    At Aminu, we believe in creating products that not only benefit your skin but also the planet. Our vegan skincare line is crafted with care, ensuring that each product is both effective and ethical.
    
    ---
    
    Explore our range of vegan skincare products and join us in making a positive impact!
        `,
    },
  ];

  const steps = [
    {
      id: 1,
      title: "Assess",
      description:
        "Take our comprehensive skin quiz to help us understand your unique needs. ",
      image: "step1.jpg",
      video: "",
    },
    {
      id: 2,
      title: "Customize",
      description:
        " Our experts craft a personalized skincare routine just for you.You will have follow up sessions where we guide you for internal health management for well balanced skin.",
      image: "",
      video: "step2.mp4",
    },
    {
      id: 3,
      title: "Deliver",
      description:
        " Receive your eco-friendly package of tailored skincare products. ",
      image: "step3.jpg",
      video: "",
    },
    {
      id: 4,
      title: "Glow",
      description:
        "Follow your simple routine and watch your skin transform naturally. ",
      image: "step4.jpg",
      video: "",
    },
  ];

  const handleReadMore = (card) => {
    setSelectedCard(card);
    navigate(`/card/${card.id}`, { state: { cardData: card } }); // Use navigate to route
  };
  return (
    <>
    


















      <div className="post-a">
        <img src="post-a.webp" alt="Poster" className="post-a-image" />
      </div>

      <div className="plan-section">
      <div className="plan premium" data-aos="fade-down">
        <h2>We are all about Skin Health!</h2>
        <p>Discover the Passion Behind</p>
      </div>
      
      <div className="plan advanced"  data-aos="fade-up">
    
        <p className="bp">At 3E SKINROUTE, we're on a mission to help people take Plant Based Skincare products as lifestyle change rather than just beauty solutions. We believe that everyone deserves a skincare routine that's as unique as they are. By combining the power of nature with cutting-edge science, we create personalized, plant-based solutions that address your specific skin concerns.</p>
       <p className="bp">Our Expert formulators work tirelessly to source the most effective, sustainable ingredients from around the world. We're committed to transparency, sustainability, and results you can see and feel. Join us on the path to healthier, happier skin – naturally.</p>
      </div>
      
     
    </div>

{/*-
      <h1 className="post-a-heading">Brand Story</h1>

      <div className="post-a-content">
        <div className="left-a">
          <h2>We are all about Skin Health!</h2>
          <h4>Discover the Passion Behind</h4>
        </div>
        <div className="right-a">
          <p>
            At 3E SKINROUTE, we're on a mission to help people take Plant Based
            Skincare products as lifestyle change rather than just beauty
            solutions. We believe that everyone deserves a skincare routine
            that's as unique as they are. By combining the power of nature with
            cutting-edge science, we create personalized, plant-based solutions
            that address your specific skin concerns.
          </p>
          <p>
            Our Expert formulators work tirelessly to source the most effective,
            sustainable ingredients from around the world. We're committed to
            transparency, sustainability, and results you can see and feel. Join
            us on the path to healthier, happier skin – naturally.
          </p>
        </div>
      </div>
      -*/}
{/*-
      <div className="l-story-container">
        <img src="logo.png" alt="Story" className="l-story-img" />
        <div className="l-s">
          <h1>Radiance in Every Drop: The Tale Behind Our Emblem</h1>
          <h5>
            Our logo is more than just a symbol—it embodies the journey to
            radiant, healthy skin. The elegant leaf design reflects our
            deep-rooted commitment to nature, representing the power of clean,
            plant-based ingredients. At its center, the graceful droplet
            signifies hydration, the key to glowing skin, while the gentle
            curves mirror the simplicity and purity found in each of our
            formulations. The pastel tones evoke calmness and balance, much like
            the peace we aim to bring to your skincare routine. With our logo,
            we invite you to embrace the beauty of nature and the confidence
            that comes with truly nourished skin.
          </h5>
        </div>
      </div>
      -*/}


 
      <section className="logo-s">
      <div className="l-ll">
        <h1>Radiance in Every Drop: The Tale Behind Our Emblem</h1>
        <h5>Our logo is more than just a symbol—it embodies the journey to
            radiant, healthy skin. The elegant leaf design reflects our
            deep-rooted commitment to nature, representing the power of clean,
            plant-based ingredients. At its center, the graceful droplet
            signifies hydration, the key to glowing skin, while the gentle
            curves mirror the simplicity and purity found in each of our
            formulations. The pastel tones evoke calmness and balance, much like
            the peace we aim to bring to your skincare routine. With our logo,
            we invite you to embrace the beauty of nature and the confidence
            that comes with truly nourished skin.</h5>
      </div>
      <div className="r-ll">
        <img src="ll.png" alt="Logo" className="l-rr" />
      </div>
    </section>



     {/*- <section className="o-story">
        <h2>Our Story</h2>
        <div className="content">
          <h1 className="h-st">Our Success Journey</h1>
          <p>
          Welcome to our skincare brand, where we believe in nurturing your natural beauty with clean, conscious, and plant-based products. Our journey began with a passion for creating skin care that not only enhances your glow but also protects and nourishes your skin with the finest ingredients.
          </p>
          <p>
          From the very start, our mission has been to bring you safe, effective, and sustainable skincare that you can trust. Every product is thoughtfully crafted to meet the highest standards of quality, ensuring your skin feels as good as it looks.
          </p>
        </div>
      </section>
      -*/}
      <Story/>

      <section className="n-incre">
        <h2>Our Nature's Best in Bottle</h2>
        <div className="grid-container">
          {cards.map((card) => (
            <div key={card.id} className="n-incre-card">
              <img src={card.image} alt={card.title} className="card-image" />
              <div className="card-content">
                <h3>{card.title}</h3>
                <p>{card.description}</p>
                <button
                  className="read-more4"
                  onClick={() => handleReadMore(card)}
                >
                  Read More
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="step-a">
        <h2>How It Works</h2>
        <div className="step-container">
          {steps.map((step, index) => (
            <div key={step.id} className="step-card">
              <div className="step-media">
                {step.video && (
                  <video
                    className={`step-video ${
                      index % 2 === 0 ? "show" : "hide"
                    }`}
                    src={step.video}
                    autoPlay
                    loop
                    muted
                  />
                )}
                {step.image && (
                  <img
                    className={`step-image ${
                      index % 2 === 0 ? "hide" : "show"
                    }`}
                    src={step.image}
                    alt={step.title}
                  />
                )}
              </div>
              <div className="step-content">
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
              {index < steps.length - 1 && (
                <div className="arrow-animation">
                  <div className="arrow"></div>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
      <Brands/>
    </>
  );
};

export default About;

import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../Navbar/Navbar.css';

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleDropdown = useCallback(() => {
    setDropdownOpen(prev => !prev);
  }, []);

  const toggleMenu = useCallback(() => {
    setMenuOpen(prev => !prev);
  }, []);

  const closeMenu = () => {
    setMenuOpen(false);
    setDropdownOpen(false); // Close the dropdown too if it's open
  };

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <Link to="/">
        <div className="logo">
          <img src='/logo.png' className='logo-h' alt='logo'/>
        </div>
      </Link>
      <div className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <a href="/" onClick={closeMenu}>Home</a>
        {/* 
        <div className={`nav-item ${dropdownOpen ? 'open' : ''}`}>
          <a href="#" onClick={toggleDropdown}>Shop</a>
          {dropdownOpen && (
            <div className="dropdown">
              <div className="dropdown-content">
                <div className="dropdown-section">
                  <h4>Product Type</h4>
                  <div className="dropdown-links">
                    <a href="/product1" onClick={closeMenu}>Cleanser</a>
                    <a href="/product2" onClick={closeMenu}>Exfoliators</a>
                    <a href="/product3" onClick={closeMenu}>Masks</a>
                    <a href="/product4" onClick={closeMenu}>Serum & Treatments</a>
                    <a href="/product5" onClick={closeMenu}>Moisturizers & Oil</a>
                    <a href="/product6" onClick={closeMenu}>Ritual Sets</a>
                    <a href="/product7" onClick={closeMenu}>Face Care</a>
                    <a href="/product8" onClick={closeMenu}>Body Care</a>
                  </div>
                </div>
                <div className="dropdown-section">
                  <h4>Skin Mood/Type</h4>
                  <div className="dropdown-links">
                    <a href="/product6" onClick={closeMenu}>Normal Skin Mood</a>
                    <a href="/product7" onClick={closeMenu}>Combination Skin Mood</a>
                    <a href="/product8" onClick={closeMenu}>Dry Skin Mood</a>
                    <a href="/product9" onClick={closeMenu}>Oily Skin Mood</a>
                    <a href="/product10" onClick={closeMenu}>Sensitive Skin Mood</a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        */}
        <Link to="/shop" onClick={closeMenu}>Products</Link>
        <Link to="/blog" onClick={closeMenu}>Blogs</Link>
        <a href="/ingredients" onClick={closeMenu}>Ingredients</a>
        <a href="/about" onClick={closeMenu}>About</a>
        <a href="/contact" onClick={closeMenu}>Book Your Consultation</a>
      </div>

      {/* Hamburger Menu */}
      <div className="hamburger" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
};

export default Navbar;

import React, { useState } from "react";
import "../Faq/Faq.css";

const Faq = () => {
  const [selected, setSelected] = useState(null);

  const toggleFaq = (index) => {
    setSelected(selected === index ? null : index);
  };

  const faqs = [
    {
      question:
        "What makes 3E SKINROUTE plant-based skincare different from other natural brands?",
      answer:
        "Our approach is truly personalized. We create custom formulations tailored to your unique skin needs, using only the highest quality plant-based ingredients, including actives, emulsifiers, preservatives, polymers, and rheology modifiers. This ensures maximum effectiveness and results for your specific skin concerns.",
    },
    {
      question: "How do I know which products are right for my skin?",
      answer:
        "We offer personalized skincare consultations to determine your skin type, concerns, and goals. Many people know which active ingredients suit them, but using the same ingredient for years can make the skin sensitive over time. We provide complete guidelines and follow-ups to recommend a customized routine using our plant-based formulations.",
    },
    {
      question: "Are your products suitable for sensitive skin?",
      answer:
        "Absolutely! Our gentle, plant-based formulations are designed to be kind to all skin types, including sensitive skin. We have specific solutions tailored for those with sensitivity concerns.",
    },
    {
      question:
        "How long will it take to see results from my customized skincare routine?",
      answer:
        "While everyone’s skin is different, many clients report seeing improvements within 4-6 weeks of consistent use. For optimal results, we recommend following your personalized routine for at least 12 weeks. For younger clients, results may be visible within 6-8 weeks, while older clients (35-40) may see results in 8-12 weeks depending on the issue.",
    },
    {
      question: "Are your products vegan and cruelty-free?",
      answer:
        "Yes, all our products are 100% vegan, and we never test on animals. We are committed to ethical and sustainable beauty practices.",
    },
    {
      question: "How often should I update my skincare routine?",
      answer:
        "We recommend reassessing your skincare needs every 3-6 months or whenever you experience significant changes in your skin or environment. We offer follow-up consultations to ensure your routine remains optimal for your evolving skin needs.",
    },
    {
      question:
        "Can I use 3E SKINROUTE products if I am pregnant or breastfeeding?",
      answer:
        "All our products are safe for use during pregnancy and breastfeeding. However, we always recommend consulting with us and your healthcare provider before starting any new skincare routine during this time.",
    },
    {
      question:
        "How do you ensure the quality and safety of your plant-based ingredients?",
      answer:
        "We source our ingredients from reputable suppliers and conduct rigorous testing to ensure purity and potency. All our formulations undergo dermatological testing for safety and efficacy.",
    },
    {
      question:
        "What is your return policy if I am not satisfied with a product?",
      answer:
        "We offer a 30-day satisfaction guarantee. If you’re not happy with your purchase, we’ll work with you to find a solution or provide a full refund.",
    },
    {
      question:
        "How can I make my skincare routine more environmentally friendly?",
      answer:
        "By choosing 3E SKINROUTE, you are already making an eco-conscious choice. Our products come in sustainable packaging, and we offer refill options for many items. We also provide tips on minimizing waste in your beauty routine.",
    },
  ];

  return (
    <div className="full-faq">
    <img src="leaf2.png" className="leave" alt="leave-img"/>
     <div className="leave-div">
    
        <h2 className="faq-heading">Start Your Plant-Powered Skin Journey</h2>
     
        </div>
      <div className="faq-section">
     
        <div className="faq-container">
          {faqs.map((faq, index) => (
            <div
              className={`faq-item ${selected === index ? "active" : ""}`}
              key={index}
              onClick={() => toggleFaq(index)}
            >
              <div className="faq-question">
                <h3 className="faq-question-text">{faq.question}</h3>
                <span className="faq-arrow">
                  {selected === index ? "▼" : "▶"}
                </span>
              </div>
              <div className={`faq-answer ${selected === index ? "show" : ""}`}>
                <p className="faq-answer-text">{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;

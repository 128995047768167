import React from 'react';
import '../Runner/Runner.css'; 

const Runner = () => {
  const text = "No SLS | No Paraben | Plant Derived | No Artificial Fragrance";

  return (
    <div className="runner-container">
      <div className="runner-content">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Runner;

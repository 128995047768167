import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Review/Review.css";
const Review = () => {
  // Array for top slider reviews
  // Array for top slider reviews
const topReviews = [
    {
      name: "Aarav, 2022",
      image: "product.png",
      text: "I have been using this product for a while now. Its quality is unmatched. Highly recommend!",
    },
    {
      name: "Isha, 2022",
      image: "product.png",
      text: "This product is amazing; I can't live without it. It works wonders on my skin.",
    },
    {
      name: "Ravi, 2022",
      image: "product.png",
      text: "This moisturizer is perfect for me. My skin feels incredibly smooth and refreshed.",
    },
  ];
  
  // Array for bottom slider reviews
  const bottomReviews = [
    {
      name: "Priya, 2021",
      image: "product.png",
      text: "This cleanser has totally transformed my skin. It feels much better and healthier now.",
    },
    {
      name: "Vikram, 2022",
      image: "product.png",
      text: "The cleanser is fantastic; it leaves my skin feeling fresh and rejuvenated.",
    },
    {
      name: "Ananya, 2022",
      image: "product.png",
      text: "I use this skincare product daily. It’s super hydrating and leaves my skin feeling lovely.",
    },
  ];
  
  // Settings for top slider
  const settingsTop = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    arrows: false,
  };

  // Settings for bottom slider (moving right to left)
  const settingsBottom = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    arrows: false,
    rtl: true, // Reverse the direction
  };

  return (
    <div className="review-container">
    <h1 className="review-head">As Our Customer Says!</h1>
      {/* Top Slider */}
      <div className="slider-wrapper top-slider">
        <Slider {...settingsTop}>
          {topReviews.map((review, index) => (
            <div key={index} className="review-card2">
              <img src={review.image} alt={review.name} />
              <div className="f-s">
              <h4>{review.name}</h4>
              <p>{review.text}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Bottom Slider */}
      <div className="slider-wrapper bottom-slider">
        <Slider {...settingsBottom}>
          {bottomReviews.map((review, index) => (
            <div key={index} className="review-card2">
            <div className="f-s"> <h4>{review.name}</h4>
              <p>{review.text}</p>
              </div> 
              <img src={review.image} alt={review.name} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Review;
